<template lang="cs">
    <div class="contentCTA">
        <router-link :to="this.pages[0].url"><div class="btn">{{this.pages[0].title}}</div></router-link>
        <router-link class="text" :to="this.pages[1].url">{{this.pages[1].title}} →</router-link>
    </div>
</template>

<script>

    export default {
        props:[
            'pages'
        ],
        data(){
            return{
            }
        }             
    }
</script>
<style scoped>
.contentCTA{
    display: flex;
    align-items: center;
    margin-top: 2rem;
    text-align: center;
    gap: 100px;
}
.btn{
    background-color: #FFBA33;
    border-radius: 50px;
    border: none;
    padding: 0.8rem;
    width: 7rem;
    color: black;
    text-align: center;
    flex: 1;
}
.btn:hover{
    background-color: #d4a704;
}
a{
    text-decoration: none;
    color: white;
    font-size: 16px;
    width: 6rem;
}
.text{
    display: flex;
    padding-left: 0;
    color: white;
}
a:visited{
    color: white;
    text-decoration: none;
}
span{
    color: black !important;
    margin-left: 2rem;
}
span a:visited{
    color: black !important;
}
a:hover, a:active{
    color: #FFBA33;
}
@media screen and (max-height: 1050px) { 
    .contentCTA{
        margin-top: 0;
        margin-bottom: 1rem;
    }
}
</style>