<template>
    <div class="home">
      <div class="content">
        <div class="left">
          <h1>
            <span>Software</span> <br/> Který Budete Milovat
          </h1>
          <div class="description">
            <p>Zdravím! Jsem webová designerka a vývojářka, programátorka a obsahová marketérka z Prahy.  Buďte na internetu vidět! Individuální přístup a dlouhodobá podpora je pro mě samozřejmostí.</p>
            <cta :pages="this.pages"></cta>
          </div>
        </div>
        <img class="pc" src="../assets/homepage.png"/>
        <img class="mobile person" src="../assets/homepage_person.png"/>
        <img class="mobile bottom" src="../assets/bottom.svg"/>
      </div>
    </div>

</template>

<script>
import cta from '@/components/cta.vue';
export default {
  components: {
   cta
  },
  data(){
    return{
      pages: [{url: '/contact', title: 'Chci software'},
              {url: '/about', title: 'O mně'}]
    }
  },
}
</script>
<style scoped>
.home{
  background-image: url("../assets/bcg.svg");
  height: 100%;
  background-size: cover;
  background-position: top;
}
.content{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  color: #F8E5FF;
  padding-top: 70px;
  display: flex;
  justify-content: space-between;
  height: 100%;
}
span{
  color: #FFBA33;
}
h1{
  font-size: 55px;
}
.content img{
  margin-top: -70px;
  margin-right: 3%;
  height:70vh;
  width: auto;
}
.description{
  color: white;
}
.description p{
  font-size: 22px;

}
.left{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23vw;
  height: 65%;
  align-items: flex-start;
}
.mobile{
  display: none;
}
@media screen and (max-width: 1920px) {
  .left{
    width: 31vw;
  }
}
@media screen and (max-width: 1500px) {
  .left{
    height: 65%;
    width: 34vw;
  }
  .description p{
    font-size: 16px;
  }
  h1{
    font-size: 30px;
  }
  .content img{
    height: 60vh;
  }
}
@media screen and (max-height: 1050px) {
  .left{
    height: 67%;
  }
  .content{
    padding-top: 0;
  }
  .description p{
    font-size: 17px;
  }
  h1{
    font-size: 35px;
  }
}
@media screen and (min-width: 3410px) {
  .left{
   height: 40%;
  }
}

@media screen and (max-width: 1000px) {
  .pc{
    display: none;
  }
  .mobile{
    display: block;
  }
  .home{
    background: none;
  }
  .left{
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
  .content{
    height: 100%;
    width: 100%;
    padding-top: 0;
    flex-direction: column;
  }
  .content img{
    position: relative;
    margin: 0;
    height: auto;
    margin-top: -30%;
    pointer-events: none;
  }
  .content img.bottom{
    margin-left: -6%;
  }

}
@media screen and (max-height: 1440px) and (max-width: 1000px){
  .content img{
    margin-top: -40%;
    height: 139vh;
  }
}
@media screen and (max-height: 620px) and (max-width: 450px){
  .content img{
    margin-top: 0;
  }
}
</style>