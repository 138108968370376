<template>
  <navbar @hide="hideNav"/>
  <router-view/>
  <img id="myBtn" class="mobile toTop" src="./assets/bottom_to_top.svg" @click="moveToTop"/>
</template>
<script>
import navbar from './components/navbar.vue';
export default {
  name: 'HomeView',
  components: {
   navbar
  },
  watch: {
    '$route' (to, from){
      var app = document.getElementById('app');
      if(to.fullPath == '/projects' || to.fullPath == '/services')
        app.style.overflowY = 'visible'
      else
        app.style.overflowY = 'hidden'
    }
  },
  methods:{
    moveToTop(){
      window.scroll({
        top:0,
        left: 0,
        behavior: 'smooth'
      })
    },
    hideNav(){
      var app = document.getElementById('app');
      app.classList.toggle("oHidden")
    }
  }
}
</script>

<style>
::-webkit-scrollbar{
  width: 1em;
}
::-webkit-scrollbar-track{
  border-radius: 100vw;
  margin-block: .5em;
  background: #2b2b2b;
}
::-webkit-scrollbar-thumb{
  border-radius: 100vw;
  background: #FFBA33;
}
.oHidden{
  overflow-x: hidden;
}
html, body{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
body{
  background-color: #000728;
  font-family: 'Poppins', sans-serif;
}
#app{
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}
.mobile{
  display: none;
}
@media screen and (max-width: 1000px) {
  #app{
    overflow-y: visible !important;
  }
  .mobile{
    display: block;
  }
  .toTop{
    height: 70px;
    position: fixed;
    bottom: 26px;
    right: 28px;
  }
}
@media screen and (max-width:1150px) {
  ::-webkit-scrollbar {
      display: none;
    }
  }
</style>
